import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "./../../components/card/card.jsx";
import Swal from "sweetalert2";
import { apiUrl } from "../../config/config.js";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});

function Users() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl + "/getList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ table: "users" }),
      });

      if (response.status === 200) {
        var data = await response.json();
        data.type = "add";

        setData(data);
      } else {
        console.error(
          "Error al registrar:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error al realizar la solicitud:", error);
    }
  };

  const handleAddUser = (send) => {
    navigate("/usersDetails", { state: send });
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(apiUrl + "/actions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "delete",
          table: "users",
          data: {
            id,
          },
        }),
      });
      const responseData = await response.json();
      console.log("RESP", responseData);
      if (responseData.ok) {
        Toast.fire({
          icon: "success",
          title: responseData.message,
          background: "#1e2836",
          color: "#fff",
        });
        fetchData();
      } else {
        Toast.fire({
          icon: "error",
          title: response.message,
          color: "#fff",
          background: "#1e2836",
        });
        throw new Error(responseData.message || "Error al enviar los datos");
      }
    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message,
        background: "#1e2836",
        color: "#fff",
      });
    }
  };

  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <div>
          <h1 className="page-header mb-0">Usuarios</h1>
        </div>
        <div className="ms-auto">
          <button
            onClick={() => handleAddUser({ type: "add" })}
            className="btn btn-outline-theme"
          >
            <i className="fa fa-plus-circle fa-fw me-1"></i> Agregar Usuario
          </button>
        </div>
      </div>

      <Card>
        <div className="tab-content p-4">
          <div className="tab-pane fade show active" id="allTab">
            <div className="input-group mb-4">
              <div className="flex-fill position-relative">
                <div className="input-group">
                  <div
                    className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 pe-0"
                    style={{ zIndex: 1020 }}
                  >
                    <i className="fa fa-search opacity-5"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control ps-35px"
                    placeholder="Buscar Usuario"
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table className="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th className="pt-0 pb-2">Nombre</th>
                    <th className="pt-0 pb-2">e-mail</th>
                    <th className="pt-0 pb-2">Teléfono</th>
                    <th className="pt-0 pb-2">Identificación</th>
                    <th className="pt-0 pb-2">Acción</th>
                  </tr>
                </thead>
                {data.map((a, index) => (
                  <tbody key={index}>
                    <tr>
                      <td className="align-middle">{a.name}</td>
                      <td className="align-middle">{a.email}</td>
                      <td className="align-middle">{a.phone}</td>
                      <td className="align-middle">{a.identification}</td>
                      <td className="align-middle">
                        <button
                          onClick={() =>
                            handleAddUser({ type: "edit", data: a })
                          }
                          className="btn btn-outline-primary btn-sm mr-3"
                          style={{ marginRight: "0.75rem" }}
                        >
                          <i className="fa fa-edit"></i>
                        </button>
                        <button
                          onClick={() => handleDelete(a.id)}
                          className="btn btn-outline-danger btn-sm"
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Users;
